import { Component, computed, effect, HostBinding, inject, type Signal } from '@angular/core';
import { Router } from '@angular/router';
import { ContentBaseComponent } from '@big-direkt/content';
import { EventBusService } from '@big-direkt/event-bus';
import { PageNavigationService } from '@big-direkt/form/view';
import { UiRepository } from '@big-direkt/state/ui';
import { UserRepository, UserService } from '@big-direkt/state/user';
import { EnvironmentService } from '@big-direkt/utils/environment';
import { SeoService } from '@big-direkt/utils/seo';
import { ExternalEventService, MetadataModel, NavigateBackService, NodeTypes, type ResolvedRouteData } from '@big-direkt/utils/shared';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'big-content',
    templateUrl: './content.component.html',
})
export class ContentComponent extends ContentBaseComponent {
    public readonly pageNavigationService = inject(PageNavigationService);
    public readonly userRepository = inject(UserRepository);
    public readonly userService = inject(UserService);
    public readonly externalEventService = inject(ExternalEventService);
    public readonly eventBus = inject(EventBusService);
    public readonly environmentService = inject(EnvironmentService);
    public readonly router = inject(Router);
    private readonly uiRepository = inject(UiRepository);
    private readonly seoService = inject(SeoService);
    private readonly navigateBackService = inject(NavigateBackService);

    public readonly isFormShown = this.uiRepository.isFormShown;

    @HostBinding('class') public classList = 'block';

    public showPageTitle!: boolean;
    public textCentered = false;
    public introductionText?: string;
    public isFirstFormPage: Signal<boolean> = computed(() => this.pageNavigationService.getCurrentPageSignal()()?.previousAccessibleLeaf === undefined);

    public showWizard: Signal<boolean> = computed(() => {
        const currentPage = this.pageNavigationService.getCurrentPageSignal()();

        if (!currentPage) {
            return false;
        }

        return this.node.type === NodeTypes.Form && currentPage.isWizardVisible;
    });

    public get wizardPageCount(): number {
        return this.pageNavigationService.wizardPageCount;
    }

    public constructor() {
        super();

        effect(
            () => {
                this.uiRepository.setIsWizardShown(this.showWizard());
            },
            { allowSignalWrites: true },
        );
    }

    protected updateContentDetails(data: ResolvedRouteData): void {
        const { privatePath } = this.environmentService;

        if (privatePath) {
            this.textCentered = this.router.url.startsWith(privatePath) || this.node.type === NodeTypes.Error;
        }

        if (this.node.type === NodeTypes.Form && this.node.introduction !== undefined) {
            this.introductionText = data.content?.introduction;
        }

        this.showPageTitle = data.showPageTitle;
        if (data.content?.type !== NodeTypes.Form) {
            this.uiRepository.setIsFormShown(false);
        }
        this.seoService.initializeNode(this.node);
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        this.navigateBackService.parentUri = this.node.breadcrumbs?.at(this.node.breadcrumbs.length - 2)?.uri;
    }

    protected async trackPageInitializedEvent(): Promise<void> {
        this.eventBus.emit({
            key: 'page_initialized_event',
            data: {
                envWork: this.environmentService.workEnv,
                siteName: this.node.heading,
                pageType: this.node.type,
                pageCat1: this.node.breadcrumbs?.[1]?.title ?? '',
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                pageCat2: this.node.breadcrumbs?.[2]?.title ?? '',
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                pageCat3: this.node.breadcrumbs?.[3]?.title ?? '',
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                pageCat4: this.node.breadcrumbs?.[4]?.title ?? '',
                pageKeywords: this.node.metadata?.find((meta: MetadataModel) => meta.attributes.name === 'keywords')?.attributes.content ?? '',
                pageID: this.node.nodeId.toString() || '',
                isLoggedIn: await firstValueFrom(this.userRepository.isLoggedIn$()),
                customerSegment: await firstValueFrom(this.userService.getCustomerSegment()) ?? '',
            },
        });
    }
}
