import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, signal } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SearchFormComponent } from '@big-direkt/search';
import { UserRepository } from '@big-direkt/state/user';
import { UiLinkModule } from '@big-direkt/ui/link';
import { provideTranslationScope } from '@big-direkt/utils/i18n';
import { IconBigBoldWinkelLinks, IconBigBoldWinkelRechts, IconComponent } from '@big-direkt/utils/icons';
import { BreakpointService, type MenuItemModel } from '@big-direkt/utils/shared';
import { TranslocoDirective } from '@jsverse/transloco';
import { NavService } from '../../services/nav.service';
import { SidenavService, type NavType } from '../../services/sidenav.service';
import { NavQuicknavComponent } from '../nav-quicknav/nav-quicknav.component';
import { NavUserComponent } from '../nav-user/nav-user.component';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'big-nav-sidenav',
    templateUrl: './nav-sidenav.component.html',
    standalone: true,
    imports: [
        MatSidenavModule,
        IconComponent,
        NavQuicknavComponent,
        NavUserComponent,
        NgClass,
        UiLinkModule,
        NgTemplateOutlet,
        SearchFormComponent,
        TranslocoDirective,
    ],
    providers: [provideTranslationScope('nav', /* istanbul ignore next */ async (lang: string, root: string) => import(`../${root}/${lang}.json`))],
})
export class NavSidenavComponent {
    private readonly _subnavOpened = signal(false);
    public readonly sidenavService = inject(SidenavService);
    public readonly navService = inject(NavService);
    public readonly viewport = inject(BreakpointService);
    public readonly iconAngleRight = IconBigBoldWinkelRechts;
    public readonly iconAngleLeft = IconBigBoldWinkelLinks;
    private readonly userRepository = inject(UserRepository);
    public readonly isLoggedIn = this.userRepository.isLoggedIn;

    public topGap = computed(() => this.viewport.headerSize().height + this.viewport.topBarSize().height);
    public isOpened = computed(() => {
        switch (this.sidenavService.currentSidenav()) {
            case 'nav':
            case 'user':
                return !this.viewport.isDesktop();
            case 'search':
                return this.viewport.isMobile();
            default:
                return false;
        }
    });
    public subnavOpened = computed(() => this._subnavOpened() && this.isOpened());
    public subnavMenu = signal<MenuItemModel | undefined>(undefined);

    private parentNav?: NavType;

    public constructor() {
        effect(
            () => {
                const nav = this.sidenavService.currentSidenav();
                if (nav && nav !== this.parentNav) {
                    this.closeSubnav();
                }
            },
            { allowSignalWrites: true },
        );
    }

    public openSubnav(menu: MenuItemModel): void {
        this.parentNav = this.sidenavService.currentSidenav();
        this.subnavMenu.set(menu);
        this._subnavOpened.set(true);
    }

    public closeSubnav(): void {
        this.subnavMenu.set(undefined);
        this._subnavOpened.set(false);
    }

    public onNavItemSelected(): void {
        this._subnavOpened.set(false);
        this.sidenavService.closeSidenav();
    }
}
